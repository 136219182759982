import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutline';
import Label from '../../../components/Label';

const useStyles = makeStyles((theme) => ({
  root: {},
  fontWeightMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const BasicsInfo = ({
                      user,
                      className,
                      ...rest
                    }) => {
  const classes = useStyles();

  const fullName=(firstName,lastName)=>{
    return `${firstName} ${lastName}`
  }


  return (
      <Card
          className={clsx(classes.root, className)}
          {...rest}
      >
        <CardHeader title="User info"/>
        <Divider/>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                ID
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user._id}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Email
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.email ?? 'NULL'}
                </Typography>
                <Label color={user.accountActive == '1' ? 'success' : 'error'}>
                  {user.accountActive == '1' ? 'Active User' : 'Inactive User'}
                </Label>
                {/* <Label color={user.completed == '1' ? 'success' : 'error'}>
                  {user.completed == '1' ?
                      'User completed' :
                      'User uncompleted'}
                </Label> */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Phone
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.phoneNumber?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Full Name
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {fullName(user.lastName,user.name)}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Role
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.role?? 'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Created At
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.createdAt?user.createdAt.slice(0,10):'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.fontWeightMedium}>
                Gender
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user.gender?user.gender:'NULL'}
                </Typography>
              </TableCell>
            </TableRow>
        
        {user?.role==="user"?
         <TableRow>
         <TableCell className={classes.fontWeightMedium}>
         Section
         </TableCell>
         <TableCell>
           <Typography
               variant="body2"
               color="textSecondary"
           >
             {user?.section?user?.section?.name:'NO SECTION FOUND!'}
           </Typography>
         </TableCell>
       </TableRow>
        :
        <></>
        }   
          {user?.role==="user" && user?.section && user?.level ?
          <TableRow>
              <TableCell className={classes.fontWeightMedium}>
              Level
              </TableCell>
              <TableCell>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                  {user?.level?user?.level?.name:'NO LEVEL FOUND!'}
                </Typography>
              </TableCell>
            </TableRow>:""}  
          </TableBody>
        </Table>
        <Box
            p={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
        >
          {/* <Button startIcon={<PersonIcon/>}
                  onClick={(event) => console.log('hello')}>
            Login as User
          </Button> */}
        </Box>
      </Card>
  );
};

BasicsInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
};

export default BasicsInfo;
